import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Col, Container, Nav, Row } from "reactstrap";

import { redirectToMenu } from './shared'

const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 }
];

const Heading = () => {
  const [qrCode, setQrCode] = useState("");

  const handleOnSubmit = (event) => {
    event.preventDefault();
    redirectToMenu(qrCode)
  }

  return (
    <header className="header alter1-header section text-contrast">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="6">
            <span className="rounded-pill shadow-box bg-contrast text-dark bold py-2 px-4">
              <FontAwesomeIcon
                icon={["far", "lightbulb"]}
                className="text-primary mr-2"
              />
              <span className="text-primary">Pedimos </span>
            </span>

            <h1 className="text-contrast display-4 display-md-3">
              <span className="bold">Pedimos nas redes sociais</span>
            </h1>
            <p className="lead bold">Praticidade &amp; Modernidade</p>

            <p className="lead">
            Digite o código que aparece no QR-Code que está fixado na sua mesa e clique em buscar.
            </p>

            <form
              className="form text-center"
              data-response-message-animation="slide-in-left"
              onSubmit={handleOnSubmit}
            >
              <div className="form-group input--group">
                <input
                  type="text"
                  name="qrcode"
                  style={{ 
                    fontSize: 32,
                    textTransform: "uppercase",
                  }}
                  maxLength={3}
                  autoFocus
                  className="form-control form-control-lg"
                  placeholder="Informe o QR-Code"
                  value={qrCode}
                  onChange={(event) => setQrCode(event.target.value)}
                  required
                />
              </div>
              <Nav className="my-5" tag="nav">
              <button
                type="submit"
                className="btn btn-rounded btn-alternate mr-2 mr-md-5"
              >
                Consultar
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="ml-2"
                />
              </button>
            </Nav>
            </form>
          </Col>

          <Col md="6">
            <div className="iphone front mx-auto">
              <div className="screen shadow-box">
                <img src={require("assets/img/screens/app/9.png")} alt="..." />
              </div>

              <div className="notch" />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;

import React from "react";

// shared page components
import ContactQR from "../shared/ContactQR.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

// QRFolder 1 page components
import HeadingQR from "../QRFolder/HeadingQR.jsx";
import FeaturesCardQR from "../QRFolder/FeaturesCardQR.jsx";
import WhyQR from "../QRFolder/WhyQR.jsx";
import FaqsQR from "../QRFolder/FaqsQR.jsx";
import PricingQR from "../QRFolder/PricingQR.jsx";

class QRFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactMessageFromPricingSelected: "",
    };
  }

  render() {
    const { contactMessageFromPricingSelected } = this.state;
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 1 specific components */}
          <HeadingQR />

          <FeaturesCardQR />
          <WhyQR />
          {/*<Proposal />*/}

          {/* Shared Components */}
          {/*<PowerfulFeaturesQR />*/}
          {/*<Dashboard />*/}
          {/*<Marketplace />*/}
          <PricingQR
            contactMessageStateChange={(value) => {
              this.setState({ contactMessageFromPricingSelected: value });
            }}
          />
          {/*<Integration />*/}

          <FaqsQR />
          {/*<StartFree />*/}
          <ContactQR message={contactMessageFromPricingSelected} />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default QRFolder;

import React, { useState } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PricingPlanCD from "./PricingPlanCD";

const plans = [
  {
    id: 'default',
    name: "Padrão",
    desc: "QR-Code em vinil.",
    class: "order-md-first",
    price: { monthly:"49,90", yearly: 120.00 },
    qr: "10 QR-Codes vinil",
    delivery: "Frete grátis",
    products: "Produtos Ilimitados",
    domain: "Domínio fixo",
    support: "Suporte por e-mail/whatsapp",
    monthly: "R$ 19,90/mês",
  },
  {
    id: 'custom',
    name: "Personalizado",
    desc: "Cardápio personalizado.",
    class: "order-md-last",
    price: { monthly: "149,90", yearly: 120.00},
    qr: "10 QR-Codes alumínio",
    delivery: "Frete grátis",
    products: "Produtos Ilimitados",
    domain: "Domínio fixo",
    support: "Suporte por e-mail/whatsapp",
    monthly: "R$ 19,90/mês",
  },
  {
    id: 'premium',
    name: "Avançado",
    desc: "QR-Code em alumínio.",
    price: { monthly: "99,90", yearly: 120.00 },
    best: true,
    qr: "10 QR-Codes alumínio",
    delivery: "Frete grátis",
    products: "Produtos Ilimitados",
    domain: "Domínio personalizado",
    support: "Suporte por e-mail/whatsapp",
    monthly: "R$ 19,90/mês",
  }
];

const Pricing = props => {
  const [basis, setBasis] = useState("monthly");
  const { contactMessageStateChange } = props

  return (
    <section className="section pricing-plans">
      <div className="shapes-container overflow-clear">
        <div className="shape shape-1" />
        <div className="shape shape-2" />
        <div className="shape shape-3" />
        <div className="shape shape-4" />

        <div className="icon-holder">
          <i className="icon pe pe-7s-cash pe-3x" />

          <FontAwesomeIcon
            icon={["fas", "dollar-sign"]}
            className="icon fa-3x"
          />

          <i className="icon pe pe-7s-piggy pe-3x" />

          <i className="icon pe pe-7s-cart pe-3x" />

          <FontAwesomeIcon
            icon={["far", "credit-card"]}
            className="icon fa-3x"
          />

          <FontAwesomeIcon
            icon={["far", "money-bill-alt"]}
            className="icon fa-3x"
          />
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <span className="rounded-pill shadow-box bold py-2 px-4">
            <FontAwesomeIcon
              icon={["far", "lightbulb"]}
              className="text-primary mr-2"
            />
            <span className="text-primary">Economize</span> com esses planos incríveis para o Cardápio Digital.
          </span>
          <h2 className="mt-3 heading-line" style={{textAlign:"center"}}>Preço transparente</h2>
          <p className="lead text-muted" style={{textAlign:"center"}}>
          Nossos planos têm tudo que você precisa para alavancar seu negócio. <span className="highlight">Sem taxas ocultas.</span>
          </p>

          <ButtonGroup>
            <Button
              color="secondary"
              onClick={e => setBasis("monthly")}
              active={basis === "monthly"}
            >
              Mensal
            </Button>
          </ButtonGroup>
           
        </div>

        <Row className="no-gutters align-items-center pricing-plans-options">
          {plans.map((plan, index) => (
            <PricingPlanCD key={`pricing-plan-${index}`} {...plan} basis={basis} contactMessageStateChange={contactMessageStateChange} />
          ))}
        </Row>
      </Container>

      <Container className="pt-0 border-bottom">
        <Row className="align-items-center">
          <Col md="150">
            <div className="text-center text-md-left">
              <p className="mt-0">
                
                {
                /*<span className="italic dotted">
                  Converse conosco! Seremos bem claro e objetivo. Conheça mais nossos produtos.
                </span>
                */}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;
import React from "react";
import { Container, Row, Col } from "reactstrap";

const renderIcons = () => {
  let icons = [];
  const items = [
    { icon: "7s-hourglass", prefix: "pe" },
    { icon: "7s-magic-wand", prefix: "pe" },
    { icon: "7s-rocket", prefix: "pe" },
    { icon: "7s-plugin", prefix: "pe" },
    { icon: "7s-like", prefix: "pe" },
    { icon: "7s-clock", prefix: "pe" },
    { icon: "7s-smile", prefix: "pe" },
    { icon: "7s-piggy", prefix: "pe" },
    { icon: "7s-shield", prefix: "pe" },
    { icon: "7s-server", prefix: "pe" },
  ];
  items.forEach((icon, i) => {
    icons.push(
      <i
        className={`icon ${icon.prefix} ${i.prefix}-${icon.icon} ${icon.prefix}-3x`}
        key={i}
      />
    );
  });

  return icons;
};

const StartFree = () => {
  return (
    <section className="section start-free">
      <div className="shapes-container">{renderIcons()}</div>

      <Container>
        <Row>
          <Col md="6" className="mx-auto">
            <div className="section-heading text-center">
              <i className="pe pe-7s-unlock fa-3x text-alternate "/>
              <h2 className="heading-line bold mt-4 text-center" style={{textAlign: 'center'}}>LGPD</h2>
              <p className="lead text-muted text-left">
                Estamos preocupados com a segurança dos seus dados, por isso,
                nossos produtos, banco de dados e contratos estão em
                conformidade com a Lei Geral de Proteção de Dados. Fique por
                dentro da Lei:{" "}
                <a 
                href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
                target="_blank"
                >
                  Visualizar
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default StartFree;

import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";

const QRFolder = () => {
  return (
    <section className="section our-proposal">
      <div className="shapes-container">
        <Fade bottom delay={300}>
          <div className="shape shape-circle pattern-dots" />
        </Fade>

        <Fade bottom>
          <div className="shape shape-circle shape-circle-fill" />
        </Fade>

        <div className="pattern pattern-dots" />
      </div>

      <Container className="bring-to-front">
        <Row className="align-items-center">
          <Col md="6" className="order-md-last">
            <figure
              className="mockup"
              style={{ width: "920px", maxWidth: "90%" }}
            >
              <img
                src={require("assets/img/screens/wmockup/2.png")}
                className="img-responsive"
                alt="..."
              />
            </figure>
          </Col>

          <Col md="6">
            <h2 className="heading-line">QR Folder</h2>
            <p className="lead text-muted">
            A proposta é fixar uma placa de identificação com um qr-code para que o cliente 
            possa apontar a câmera do celular e obter os preços, promoções através da sua 
            página na internet. O melhor é que o cliente não precisa instalar nenhum programa!
            </p>
            <div class="col-7 txt-center">
              <img
                src={require("assets/img/screens/wmockup/3.png")}
                className="justifyContent: 'center'"
                width="300"
                alt=""
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default QRFolder;

import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import React from "react";
import FaqsCD from "../SocialMedia/FaqsCD.jsx";
import FeaturesCard from "../SocialMedia/FeaturesCard.jsx";
// Cardápio Digital 1 page components
import Heading from "../SocialMedia/Heading.jsx";
import PricingSM from "../SocialMedia/PricingSM.jsx";
import WhyCD from "../SocialMedia/WhyCD.jsx";
import ContactSM from "../shared/ContactSM.jsx";
// shared page components
import PowerfulFeaturesCD from "../shared/PowerfulFeaturesCD.jsx";

class SocialMedia extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactMessageFromPricingSelected: ''
    };
  }

  render() {
    const { contactMessageFromPricingSelected } = this.state
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 1 specific components */}
          <Heading />
          
          <FeaturesCard />
          <WhyCD />
          {/*<Proposal />*/}

          {/* Shared Components */}
          <PowerfulFeaturesCD />
          {/*<Dashboard />*/}
          {/*<Marketplace />*/}
          <PricingSM contactMessageStateChange={
            (value) => { this.setState({ contactMessageFromPricingSelected: value }) }
          } />
          {/*<Integration />*/}
          
          <FaqsCD />
          {/*<StartFree />*/}
          <ContactSM message={contactMessageFromPricingSelected} />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default SocialMedia;

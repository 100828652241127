import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Fade from "react-reveal/Fade";
import { Col, Container, Row } from "reactstrap";

const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
];

const HeadingQR = () => {
  return (
    <header className="header alter1-header section text-contrast">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="6">
            <span className="rounded-pill shadow-box bg-contrast text-dark bold py-2 px-4">
              <FontAwesomeIcon
                icon={["far", "lightbulb"]}
                className="text-primary mr-2"
              />
              <span className="text-primary">Pedimos </span>
            </span>
            <h1 className="text-contrast display-4 display-md-3">
              <span className="bold">QR-Folder</span>
            </h1>
            <p className="lead bold">Praticidade &amp; Modernidade</p>
            Imagine que ao chegar em um estabelecimento seja
            possível ter as informações dos produtos/serviços e preços de forma
            rápida e acessível na palma da sua mão?
            <br />O <b>QR-Folder</b> é a solução para o seu
            negócio, seu cliente terá facilidade para visualizar e realizar seus
            pedidos, além de promoções oferecidas, novidades entre outros
            criando uma conexão virtual <b>cliente x empresa</b> através de
            links das redes sociais. O QR-Folder dinâmico é uma estratégia para
            integrar o marketing offline com o online, levando os seus clientes
            para uma experiência inovadora.
          </Col>

          <Col md="6">
            <div className="iphone front mx-auto">
              <div className="screen shadow-box">
                <img src={require("assets/img/screens/app/10.png")} alt="..." />
              </div>

              <div className="notch" />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeadingQR;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/sass/cd.scss";

const renderFeatures = () => {
  let features = [];
  const items = [
    {
      name: "Integração",
      icon: "plug",
      description:
        "Com um dashboard (painel de controle) completo, faça a integração do seu cardápio fisíco com o digital.",
    },
    {
      name: "Marketing",
      icon: "heart",
      description: "Faça o marketing dos seus produtos de forma digital.",
    },
    {
      name: "Suporte",
      icon: "headphones",
      description: "Solicite ajuda que teremos o prazer em ajudá-lo.",
    },
  ];

  items.forEach((f, i) => {
    features.push(
      <li className="list-item" key={i}>
        <div className="media align-items-center">
          <div className="icon-shape mr-3">
            <div className="shape shape-pipes" />
            <FontAwesomeIcon
              icon={["fas", f.icon]}
              className="icon fa-3x text-alternate"
            />
          </div>

          <div className="media-body">
            <h5 className="bold">{f.name}</h5>
            <p className="my-0">{f.description}</p>
          </div>
        </div>
      </li>
    );
  });

  return features;
};

const WhyUs = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="section-heading text-justify" id="box">
          <h2 className="heading-line">O que é a Pedimos Cardápio Digital?</h2>
          <p className="text-muted lead">
            O Cardápio Digital é uma ferramenta que chega para modernizar o seu
            negócio, automatizando o processo do atendimento tanto 
            presencialmente, quanto nas compras através de <i>delivery.</i>{" "}
            <br />Em tempos de pandemia, como a que estamos vivenciando agora,
            ele diminui o contato dos colaboradores com os clientes, trazendo 
            uma maior comodidade, agilidade e segurança no momento do 
            atendimento. <br />
            Entendemos que é um momento diferente e que o seu negócio teve que
            se adaptar a outras formas de vendas, tornando-se indispensável um 
            cardápio digital com uma interface moderna como a nossa. <br />
            Dependendo do plano escolhido, é possível com apenas um <i>click </i> 
            o cliente fazer a sua escolha e enviar o pedido diretamente para a 
            cozinha, no seu tempo, incluindo as informações que diferenciam a 
            sua escolha. Autonomia: o cliente escolhe o pedido no seu tempo e 
            de forma personalizada. Informação completa: com apenas um <i>click</i>, 
            fotos e ingredientes estão à disposição do cliente.
          </p>
        </div>

        <div className="row gap-y">
          <div className="col-md-6">
            <h4 className="bold text-alternate">
              Consulte o cardápio lendo um qr-code.
            </h4>
            <p className="text-muted lead mb-5">
              Ofereça aos seus clientes a possibilidade de consultar o cardápio
              de forma digital, utilizando o celular.
            </p>

            <ul className="list-unstyled why-icon-list">{renderFeatures()}</ul>

            <hr className="my-5" />
            <div className="text-center text-md-left">
              <a href="#contactcd" className="btn btn-primary text-contrast">
                Quero saber mais
              </a>
            </div>
          </div>

          <div className="col-md-6">
            <div className="rotated-mockups device-twin">
              <div className="browser absolute shadow-lg">
                <img
                  src={require("assets/img/screens/tablet/1.png")}
                  alt="..."
                />
              </div>

              <div className="front iphone light">
                <div className="screen">
                  <img
                    src={require("assets/img/screens/app/1.png")}
                    alt="..."
                  />
                </div>

                <div className="notch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;

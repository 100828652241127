import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import React from "react";
import Features from "../index/Features.jsx";
// alternative 2 page components
import Heading from "../index/Heading.jsx";
import Partners from "../index/Partners.jsx";
import Contact from "../shared/ContactHome.jsx";
import StartFree from "../shared/StartFree"
// shared page components
import Dashboard from "../shared/Dashboard.jsx";
import FaqsGeneral from "../shared/FaqsGeneral.jsx";
import Marketplace from "../shared/Marketplace.jsx";
import PedimosCD from "../shared/PedimosCD.jsx";
import QRFolder from "../shared/QRFolder.jsx";



//import Footer from "../index/Footer.jsx"; 
//import GetStarted from "../index/GetStarter.jsx";



class Pedimos extends React.Component {
  render() {
    return (
      <>
        <DefaultNavbar useOnlyDarkLogo={true} />
        <main ref="main">
          {/* Alternative 2 specific components */}
          <Heading />
          <Partners />
          <Features />
          {/* Shared Components */}
          <Dashboard />
          <Marketplace />
          <PedimosCD />
          <QRFolder />

          {/*<Integration />*/}

          {/*<WhyUs />*/}
          <FaqsGeneral />
          <StartFree />
          <Contact />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Pedimos;

import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import React, {createRef, useState} from "react";
//import FaqsCD from "../Developer/FaqsCD.jsx";
//import FeaturesCard from "../CardapioDigital/FeaturesCard.jsx";
// Cardápio Digital 1 page components
import Heading from "../Developer/Heading.jsx";
//import PricingCD from "../Developer/PricingCD.jsx";

// shared page components
import ContactPDM from "../shared/ContactPDM.jsx";

import SolutionsDEV from "../Developer/SolutionsDEV.jsx";
import AppsDEV from "../Developer/AppsDEV";
import Partners from "../Developer/Partners.jsx";
import ServicesPDM from "../Developer/ServicesPDM.jsx";
import InfoDEV from "../Developer/InfoDEV.jsx";

//import ContactCD from "../shared/ContactCD.jsx";
// shared page components
//import PowerfulFeaturesCD from "../shared/PowerfulFeaturesCD.jsx";


  const Developer=()=>{
    const ref= createRef("main");
    const [inputMessageText, setInputMessageText] = useState("")

    return (
      <>
        <DefaultNavbar />
        <main ref={ref}>
          {/* Alternative 1 specific components */}
          {/* o formAction será a propriedade responsável por levar o método da mudança de estado pra dentro do botao */}
          <Heading formAction={setInputMessageText}/> 
          <InfoDEV />            
          {/*   <FeaturesCard /> */}
          <SolutionsDEV />
          <Partners />
          <AppsDEV />
          <ServicesPDM />
          <ContactPDM autoMessage={inputMessageText}/>
        </main>
        <DefaultFooter />
      </>
    );
  }


export default Developer;

import React from "react";
import { Container, Row, Col } from "reactstrap";

const renderFeatures = () => {
  let features = [];
  const elements = [
    {
      icon: "pe-7s-diamond",
      title: "Propósito",
      description:
        "O nosso propósito é agregar valor ao seu negócio. Afinal de contas, seremos parceiros."
    },
    {
      icon: "pe-7s-tools",
      title: "Equipe",
      description:
        "Contamos com um time multidisciplinar: programadores, administradores, designers, representantes e advogados."
    },
    {
      icon: "pe-7s-light",
      title: "Criação",
      description:
        "Estamos aptos para desenvolver qualquer sistema, toda solução trazida é analisada, lapidada, construída e testada."
    }
  ];

  elements.forEach((element, i) => {
    features.push(
      <Col md="4" className="py-4 rounded shadow-hover">
        <i className={`pe ${element.icon} pe-3x mb-3 text-primary`} />
        <h5 className="bold">{element.title}</h5>
        <p>{element.description}</p>
      </Col>
    );
  });

  return features;
};

const Features = () => {
  return (
    <section id="features" className="section features">
      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">Nossas vantagens</h2>
          <p className="lead text-muted text-justify">

            <ul>
              <li>Rapidez e agilidade na execução das tarefas;</li>
              <li>Automatização dos processos;</li>
              <li>Economia de tempo e dinheiro;</li>
              <li>Maior segurança nas informações empresariais;</li>
              <li>Tecnologia de ponta acessível;</li>
              <li>Gestão eficiente.</li>
            </ul>
            O momento é muito delicado, estamos passando desde 2020 por uma pandemia e inserir a sua empresa no contexto digital tornou-se imprescindível.
            Pensando nisso, a Pedimos desenvolveu diversos produtos de vendas digitais que auxiliam o seu negócio a ingressar/estar no meio, 
            facilitando que ele seja mais competitivo e, consequentemente, traga aumento das vendas.Os produtos desenvolvidos têm o tamanho que 
            a sua empresa exige, sejam eles mais simples, como cardápio digital ou até mais complexos, como aplicativos personalizados.
            Nós desenvolvemos tecnologias para empresas independentemente do tamanho, com um preço competitivo, entregando sistemas 
            completamente robustos e adequando às suas necessidades.
          </p>
        </div>

        <Row className="gap-y text-center text-md-left">{renderFeatures()}</Row>
      </Container>
    </section>
  );
};

export default Features;

import React from "react";
import "../../assets/sass/cd.scss";
import { Container, Row, Col } from "reactstrap";

const SolutionsDEV = () => {
  return (
    <section style={{marginBottom:"-150px"}}>
      <Container>
        <Row>
          <Col>
            <div className="container pb-8 bring-to-front">
              <div style={{marginBottom:"-100px"}} className="section-heading text-justify" id="box">
                <h2 className="heading-line" style={{ textAlign: "left" }}>
                  Soluções para melhorar o seu negócio
                </h2>
                <p className="text-muted lead">
                 Contribuímos e automatizamos as tarefas mais simples e mais
                 complexas, o nosso time conta com especialistas que além 
                 de orientar, desenvolvem as melhores soluções em tecnologia
                 que mais se adequam ao seu negócio. 
                </p>
              </div>
            </div>
          </Col>
          <Col>
            {" "}
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box">
                <div className="screen">
                  <img
                    src={require("assets/img/development.jpg")}
                    alt="..."
                    className="prototype"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SolutionsDEV;

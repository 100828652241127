import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {  Col, Container, Row } from "reactstrap";
import PricingPlanCD from "./PricingPlanCD.jsx";
import "../../assets/sass/pedimosnamesa.scss";

const plans = [
  {
    name: "Padrão",
    desc: "Qr-Code em pvc.",
    class: "order-md-first",
    price: { monthly: "99,90", yearly: 120.0 },
    qr: "1 qr-code",
    delivery: "Frete grátis",
    products: "Produtos ilimitados",
    domain: "Domínio fixo",
    support: "Suporte por e-mail/whatsapp",
    monthly: "R$ 19,90/mês",
  },
];

const PricingQR = (props) => {
  const [basis] = useState("monthly");
  var { contactMessageStateChange } = props;

  return (
    <section className="section pricing-plans">
      <div className="shapes-container overflow-clear">
        <div className="shape shape-1" />
        <div className="shape shape-2" />
        <div className="shape shape-3" />
        <div className="shape shape-4" />

        <div className="icon-holder">
          <i className="icon pe pe-7s-cash pe-3x" />

          <FontAwesomeIcon
            icon={["fas", "dollar-sign"]}
            className="icon fa-3x"
          />

          <i className="icon pe pe-7s-piggy pe-3x" />

          <i className="icon pe pe-7s-cart pe-3x" />

          <FontAwesomeIcon
            icon={["far", "credit-card"]}
            className="icon fa-3x"
          />

          <FontAwesomeIcon
            icon={["far", "money-bill-alt"]}
            className="icon fa-3x"
          />
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <span className="rounded-pill shadow-box bold py-2 px-4">
            <FontAwesomeIcon
              icon={["far", "lightbulb"]}
              className="text-primary mr-2"
            />
            <span className="text-primary">Economize</span> com esse plano
            incrível para o QR-Folder.
          </span>
          <h2 className="mt-3 heading-line" style={{ textAlign: "center" }}>
            Preço transparente
          </h2>
          <p className="lead text-muted" style={{ textAlign: "center" }}>
            Nossos planos têm tudo que você precisa para alavancar seu negócio.{" "}
            <span className="highlight">Sem taxas ocultas.</span>
          </p>
          {/*
          <ButtonGroup>
            <Button
              color="secondary"
              onClick={e => setBasis("monthly")}
              active={basis === "monthly"}
            >
              Mensal
            </Button>
          </ButtonGroup>
    */}
        </div>

        <Row
          className="no-gutters align-items-center pricing-plans-options"
          id="pricing-plan"
        >
          {plans.map((plan, i) => (
            <PricingPlanCD
              key={`pricing-plan-${i}`}
              {...plan}
              basis={basis}
              contactMessageStateChange={contactMessageStateChange}
            />
          ))}
        </Row>
      </Container>

      <Container className="pt-0 border-bottom">
        <Row className="align-items-center">
          <Col md="150">
            <div className="text-center text-md-left"></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PricingQR;

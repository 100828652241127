import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Col,
  Container,
  Nav,
  Row,
  Card,
  CardTitle,
  CardText,
  } from "reactstrap";
import "../../assets/sass/developer.scss";
import { PenTool, Unlock, Code, CheckCircle } from 'react-feather';
import MoreInfoButton from "components/MoreInfoButton";
import { redirectToMenu } from "./shared";
import { HashLink } from 'react-router-hash-link';


const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
];

const Heading = ({ formAction }) => {
  const AUTO_MESSAGE = "Eu tenho interesse no desenvolvimento de um software."

  const handleOnSubmit = (event) => {
    event.preventDefault();    
    formAction(AUTO_MESSAGE)
  };

  return (
    <header className="header alter-header-section1 section text-contrast">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="6">
          <img
                    src={require("assets/img/PDM_logo.svg")}
                    alt="..."
                    className=""
                    style={{width: "90%", alignItems: "center"  }}  style={{width: "80%", alignItems: "center"}}
          />             
            <p className="lead">
            Nascemos com a missão de criar soluções eficientes, 
            sejam elas complexas ou não, o importante mesmo é agregar valor, 
            simplificar e automatizar os processos do seu negócio.
            </p>
            <form 
              className="form text-center"
              data-response-message-animation="slide-in-left"
              onSubmit={handleOnSubmit}
            >
              <div className="form-group input--group">
                {/*  <input
                  type="text"
                  name="qrcode"
                  style={{
                    fontSize: 32,
                    textTransform: "uppercase",
                  }}
                  maxLength={3}
                  autoFocus
                  className="form-control form-control-lg"
                  placeholder="Informe o QR-Code"
                  value={qrCode}
                  onChange={(event) => setQrCode(event.target.value)}
                  required
                /> */}
              </div>
              <Nav className="my-5" tag="nav">              
                <HashLink smooth to="/pdm/#contactpdm">                
                  <MoreInfoButton
                    className="btn btn-rounded btn-alternate mr-2 mr-md-5"
                    text="Quero saber mais"
                    onClick={() => {
                      //event.preventDefault();
                      formAction(AUTO_MESSAGE)                   
                    }}
                  />
                </HashLink>                
              </Nav>
            </form>
          </Col>
          
          <Col md="6" style={{width: ""}}>           
            <img
              src={require("assets/img/secao1.png")}
              alt="..."
              className=""
              style={{width: "100%"}}
            />          
          </Col>          
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
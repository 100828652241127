import React from "react";
import "../../assets/sass/cd.scss";
import { Container, Row, Col } from "reactstrap";

const AppsDEV = () => {
  return (
    <section>
      <Container style={{marginBottom:"-150px"}}>
        <Row>
          <Col>
            {" "}
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box">
                <div className="screen">
                  <img
                    src={require("assets/img/dev_app.jpg")}
                    alt="..."
                    className="prototype"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box">
                <h2 className="heading-line" style={{ textAlign: "left" }}>
                  Desenvolvemos também aplicativos mobile para seu negócio
                </h2>
                <p className="text-muted lead">
                Ter todas as informações do seu negócio na palma da mão, 
                de forma facilitada significa ganho de tempo e eficiência. 
                Desenvolvemos aplicativos para os principais sistemas 
                operacionais, Android e iOS, e criamos as contas na 
                Google Play e na App Store, para que eles fiquem disponíveis 
                nas lojas virtuais.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
     
      <Container style={{marginBottom:"-150px"}}>
        <Row>
          <Col className="align-items: center">
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box"> 
                <h2 className="heading-line" style={{ textAlign: "left" }}>
                   Conheça nossas etapas de Desenvolvimento de Software
                </h2>               
                <p className="text-muted lead">
                 Resumidamente, trabalhamos com seis etapas para desenvolver um
                 sistema. A fase 1 é quando entendemos do seu problema e qual
                 é a sua necessidade. Na fase 2 é quando as primeiras telas
                 são construídas, dando uma ideia de como ficará a solução, 
                 criando assim um protótipo. A solução acontece mesmo é na 
                 fase 3, quando criamos os códigos. Os testes são feitos na 
                 fase 4, onde identificamos possíveis inconsistências do 
                 sistema. O treinamento será dado na fase 5, para que utilize 
                 de forma adequada todas as funcionalidades, e, por fim, 
                 toda a documentação é feita na fase 6.              
                </p>
              </div>
            </div>
          </Col>
          <Col>
            {" "}
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box">
                <div className="screen">
                  <img
                    src={require("assets/img/roadmap.svg")}
                    alt="..."
                    className="prototype"                    
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>      
    </section>
  );
};

export default AppsDEV;

import React from "react";
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

const questions = [
  {
    question: "Se eu tiver alguma dificuldade em cadastrar um produto, a Pedimos poderá fazer isso por mim?",
    answer:
      "Sim, podemos. Entretanto esse processo poderá levar algumas horas, dependerá da demanda dos nossos colaboradores."
  },
  {
    question: "A Pedimos pode desenvolver um aplicativo totalmente exclusivo para a minha empresa?",
    answer:
      "Sim. Entre em contato conosco que um especialista irá atendê-lo para montar um projeto."
  },
  {
    question: "O código fonte dos produtos estão inclusos?",
    answer:
      "Depende. Somente será disponibilzado o código fonte das aplicações exclusivas, ou seja, os sistemas criados sob demanda."
  },
  {
    question: "Eu posso sugerir uma nova funcionalidade ao meu produto?",
    answer:
      "É claro, você pode entrar em contato conosco para informar sobre suas necessidades. Se a sua sugestão representar algum valor para nós, podemos incluí-la como parte do serviço gratuitamente ou você pode solicitar uma construção personalizada por um custo extra. Observe que pode levar algum tempo para que o recurso seja implementado."
  }
];

const Faqs = () => {
  return (
    <section className="section faqs-dd bg-light edge top-left">
      <div className="shapes-container">
        <div className="absolute icon">
          <Fade top left>
            <FontAwesomeIcon icon={["fas", "question"]} />
          </Fade>
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line" style={{textAlign:"center"}}>Dúvidas frequentes</h2>
          <p className="lead" style={{textAlign:"center"}}>Gostaria saber mais sobre os nossos produtos?</p>
        </div>

        <Row>
          <Col lg="8" className="mx-lg-auto">
            {questions.map((faq, i) => (
              <Fade bottom key={i}>
                <div className="card shadow-box shadow-hover mb-3" key={i}>
                  <div className="card-header py-3">
                    <Link
                      to="#"
                      className="card-title collapsed"
                      id={`toggler-${i}`}
                    >
                      {faq.question}
                    </Link>
                  </div>

                  <UncontrolledCollapse toggler={`#toggler-${i}`}>
                    <div className="card-body">{faq.answer}</div>
                  </UncontrolledCollapse>
                </div>
              </Fade>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;

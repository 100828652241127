import React from 'react'
import { parse } from 'querystring'
import { Redirect } from 'react-router-dom'

import { redirectToMenu } from '../../views/CardapioDigital/shared'

function RedirectToMenu (props) {
  const queryStrings = props.location.search.slice(1).toLowerCase()
  const { qrcode } = parse(queryStrings)

  if(!qrcode) {
    return <Redirect to="/" />
  }

  const wasRedirect = redirectToMenu(qrcode)
  if(!wasRedirect) {
    return <Redirect to="/" />
  }
}

export default RedirectToMenu
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import Typist from 'react-typist';

const shapes = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },

  { duration: 500, delay: 200 },
  { duration: 500, delay: 200 },

  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },

  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 }
];

const Heading = () => {
  return (
    <header className="header alter2-header section">
      <div className="shapes-container">
        {/* diagonal shapes */}
        {shapes.map((shape, i) => (
          <Fade bottom>
            <div
              className="shape shape-animated shape-diagonal"
              duration={shape.duration}
              delay={shape.delay}
            />
          </Fade>
        ))}

        {/* animated shapes */}
        <div className="animation-shape shape-cog animation--clockwise">
          <FontAwesomeIcon
            icon={['fa', 'cog']}
            className="icon"
            size="lg"
          />
        </div>
        <div className="animation-shape shape-circle shape-circle-1 animation--anti-clockwise" />
        <div className="animation-shape shape-circle shape-circle-2 animation--clockwise" />
        <div className="animation-shape shape-shopping-cart animation--clockwise">
          <div className="animation--rotating">
            <FontAwesomeIcon
              icon={['fa', 'shopping-cart']}
              className="icon"
              size="lg"
            />
          </div>
        </div>

        <div className="animation-shape shape-triangle animation--rotating-diagonal">
          <div className="animation--rotating" />
        </div>
        <div className="animation-shape shape-diamond animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>

        {/* static shapes */}
        <div className="static-shape shape-ring-1" />
        <div className="static-shape shape-ring-2" />

        <div className="static-shape shape-circle shape-circle-1">
          <Fade top right>
            <div />
          </Fade>
        </div>

        <div className="static-shape shape-circle shape-circle-2">
          <Fade top right delay={500}>
            <div />
          </Fade>
        </div>

        <div className="static-shape pattern-dots-1" />
        <div className="static-shape pattern-dots-2" />

        {/*  main shape */}
        <div className="static-shape background-shape-main" />

        {/*  ghost shapes */}
        <div className="static-shape ghost-shape ghost-shape-1" />
      </div>

      <Container>
        <Row>
          <Col md="6">
            <span className="rounded-pill shadow-box bg-contrast text-dark bold py-2 px-4">
              <FontAwesomeIcon
                icon={["far", "lightbulb"]}
                className="text-primary mr-2"
              />
              <span className="text-primary">Pedimos</span> Software de Gestão
            </span>

            <h1 className="display-4 display-md-2 mt-3">
              <span className="font-autography">Pedimos</span>
            </h1>
            <p className="lead bold text-primary">
              Desenvolvimento &amp;{" "}
              <span className="head-line-2">Marketplace</span>
            </p>
            <p className="lead">
              Somos uma startup, empresa de tecnologia, que desenvolve soluções para o seu negócio,
              <Typist cursor={{ blink: false, hideWhenDone: true }} >seja ele qual for, do tamanho que for.</Typist>
              {/* <Typist
                className="TypistEffect-message"
                cursor={{ hideWhenDone: false }}
              >
                <Typist.Delay ms={1250} />
                <a href="#" className="flash">PEDIMOS SOFTWARES DE GESTÃO</a>
              </Typist>*/}
            </p>
            <p className="lead">
              Buscamos manter o contato constante com o nosso cliente para que as soluções impactem diretamente na gestão.
            </p>
            <h5>
              Conheça a nossa plataforma de soluções:
            </h5>

            {/*


Somos uma startup, empresa de tecnologia, que desenvolve soluções para o seu negócio, seja ele qual for, do tamanho que for. 

Buscamos manter o contato constante com o nosso cliente para que as soluções impactem diretamente na gestão. 

Conheça a nossa plataforma de soluções:



            <nav className="nav my-5">
              <a
                href="#!"
                className="btn btn-rounded btn-alternate mr-2 mr-md-5"
              >
                Know more{" "}
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="ml-2"
                />
              </a>
              <a
                href="#demos"
                className="btn btn-rounded btn-outline-alternate scrollto"
              >
                Free trial
              </a>
            </nav>
*/}
          </Col>

          <Col md="6">
            <div className="iphone front mx-auto">
              <div className="screen shadow-box">
                <img src={require("assets/img/screens/app/3.png")} alt="..." />
              </div>
              <div className="notch" />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;

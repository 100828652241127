import React, { useState } from "react";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import '../../assets/sass/navbar.scss';

import { API } from '../../services'

const AVAILABLE_STATUS = {
  DEFAULT: 0,
  SENT: 1,
  ERROR: 2
}

const messages = {
  SENT: {
    title: 'Contato Recebido',
    message: 'Um dos nossos consultores irá entrar em contato o mais breve possível.'
  },
  ERROR: {
    title: 'Ocorreu um erro inesperado',
    message: 'Por favor, envie um e-mail para atendimento@pedimos.com.br.'
  }
}

const ContactCD = (props) => {
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  })

  const [status, setStatus] = useState(AVAILABLE_STATUS.DEFAULT)

  const handleInputForm = ({ target }) => {
    const { name, value } = target

    setContactForm(contactForm => ({
      ...contactForm,
      [name]: value
    }))
  }

  const sendContact = () => {
    API.post('/contacts', contactForm)
      .then(() => {
        setStatus(AVAILABLE_STATUS.SENT)
      })
      .catch(() => {
        setStatus(AVAILABLE_STATUS.ERROR)
      })
  }

  return (
    <section className="section stay-tunned" id="contactcd">
      <Container className="bring-to-front" fluid={true}>
        <div className="shadow rounded p-5 bg-contrast overflow-hidden">
          <div className="section-heading text-center">
            <h2 className="heading-line" style={{textAlign:"center"}}>Estou interessado</h2>
            <p className="lead text-alternate" style={{textAlign:"center"}}>
              Informe seus dados que um de nossos representantes irá entrar em contato para te explicar melhor.
            </p>
          </div>

          <div className="mx-auto position-relative form-wrapper">
            <div className="form text-center">
              <div className="form-group input--group">
                <input
                  id="name"
                  type="text"
                  name="name"
                  size="10"
                  maxLength="50"
                  className="form-control form-control-lg text-left"
                  placeholder="Digite o seu nome"
                  required
                  value={contactForm.name}
                  onChange={handleInputForm}
                />
              </div>

              <div className="form-group input--group">
                <input
                  id="txtemail"
                  type="email"
                  name="email"
                  size="40"
                  maxLength="60"
                  className="form-control form-control-lg text-left"
                  placeholder="Informe seu e-mail"
                  required
                  value={contactForm.email}
                  onChange={handleInputForm}
                />
              </div>

              <div className="form-group input--group">
                <input
                  id="txtphone"
                  type="text"
                  name="phone"
                  maxLength="20"
                  className="form-control form-control-lg text-left"
                  placeholder="Digite o seu telefone de contato"
                  required
                  value={contactForm.phone}
                  onChange={handleInputForm}
                />
              </div>


              <div className="form-group">
                <textarea className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="10"
                  name="message"
                  placeholder="Digite a sua mensagem"
                  maxLength="5000"
                  onChange={handleInputForm}
                  value={ 
                    props.message !== '' && contactForm.message === ''
                    ? `Olá! Eu tenho interesse no Cardápio Digital, a modalidade ${props.message}.` 
                    : contactForm.message }>
                </textarea>
              </div>

              <button onClick={sendContact} className="btn btn-lg btn-alternate">
                Enviar
              </button>
            </div>

            {
              status !== AVAILABLE_STATUS.DEFAULT && (
                <div className="response-message">
                  <FontAwesomeIcon icon={["fas", "envelope"]} className="fa-3x" />
                  <p className="font-md m-0">
                    {
                      status === AVAILABLE_STATUS.SENT
                        ? messages.SENT.title
                        : messages.ERROR.title
                    }
                  </p>
                  <p className="response">
                    {
                      status === AVAILABLE_STATUS.SENT
                        ? messages.SENT.message
                        : messages.ERROR.message
                    }
                  </p>
                </div>
              )
            }
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactCD;

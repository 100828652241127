import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Container,
  Nav,
  Row,
  Card,
  CardTitle,
  CardText,
  } from "reactstrap";
import "../../assets/sass/cd.scss";
import { PenTool, Unlock, Code, CheckCircle } from 'react-feather';
import { redirectToMenu } from "./shared";


const InfoDEV = () => {
  const [qrCode] = useState("");

  const handleOnSubmit = (event) => {
    event.preventDefault();
    redirectToMenu(qrCode);
  };
  return (
    <section style={{marginBottom:"-100px"}}>
       <Container className="container">
        <Row>
          <Col md="6">
            <span className="btn-primary rounded-pill shadow-box text-dark bold py-2 px-4"> {/*bg-contrast antes do text*/}
              <FontAwesomeIcon
                icon={["far", "lightbulb"]}
                className="text-white mr-2"
              />
              <span className="text-white">Pedimos </span>
            </span>

            <h1 className="text-contrast display-4 display-md-3">
              <span className="bold black">PDM Development</span>
            </h1>
            <p className="lead bold">Você idealiza, em conjunto planejamos e a PDM desenvolve</p>

            <p className="lead">
              Com a Pedimos Development você pode desenvolver grandes soluções
              para o seu negócio.
            </p>

            <form
              className="form text-center"
              data-response-message-animation="slide-in-left"
              onSubmit={handleOnSubmit}
            >
              {/*<Nav className="my-5" tag="nav">
                <button
                  type="submit"
                  className="btn btn-rounded btn-alternate mr-2 mr-md-5"
                >
                  Nossos serviços
                  <FontAwesomeIcon
                    icon={["fas", "long-arrow-alt-right"]}
                    className="ml-2"
                  />
                </button>
              </Nav>*/}
            </form>
          </Col>

          <Col md="6">
            <Row>
              <Col sm="6">
                <Card body className="card" style={{ backgroundColor: 'transparent'}}>
                <PenTool size={20}  className="camera-icon text-primary"/>
                  <CardTitle className="title" tag="h5">
                    Design
                  </CardTitle>
                  <CardText style={{ marginTop: '0px', textAlign: 'left' }}>
                  A apresentação da sua marca é muito importante para nós, 
                  por isso, desenhamos todo o seu aplicativo e website.
                  </CardText>
                </Card>
              </Col>
              <Col sm="6">
                <Card body  className="card" style={{ backgroundColor: 'transparent'}}>
                <Code size={20}  className="camera-icon text-primary"/>
                  <CardTitle tag="h5" className="title">
                    Software
                  </CardTitle>
                  <CardText style={{ marginTop: '0px' , textAlign: 'left'}}>
                  Soluções criadas de forma customizadas, para atender 
                  as suas necessidades e o seu propósito. 
                  </CardText>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm="6">
                <Card body  className="card" style={{backgroundColor: 'transparent'}}>
                <CheckCircle  size={20}  className="camera-icon text-primary"/>
                  <CardTitle className="title" tag="h5">
                    Qualidade
                  </CardTitle>
                  <CardText style={{ marginTop: '0px' , textAlign: 'left' }}>
                  As soluções são testadas antes da entrega e somente são 
                  liberadas para utilização, após a realização de incansáveis 
                  testes.                            
                  </CardText>
                </Card>
              </Col>
              <Col sm="6">
                <Card body  className="card" style={{ backgroundColor: 'transparent'}}>
                <Unlock size={20}  className="camera-icon text-primary"/>
                  <CardTitle tag="h5" className="title">
                    Segurança 
                  </CardTitle>
                  <CardText style={{ marginTop: '0px' , textAlign: 'left'}}>
                   Os algoritmos que utilizamos são modernos e tem a finalidade
                   de proteger os seus dados e estar em conformidade com a LGPD.
                  </CardText>
                </Card>
              </Col>
            </Row>
          </Col>

          
        </Row>
      </Container>
    </section>
  );
};

export default InfoDEV;


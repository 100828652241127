import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import React from "react";
import FaqsCD from "../CardapioDigital/FaqsCD.jsx";
import FeaturesCard from "../CardapioDigital/FeaturesCard.jsx";
// Cardápio Digital 1 page components
import Heading from "../CardapioDigital/Heading.jsx";
import PricingCD from "../CardapioDigital/PricingCD.jsx";
import WhyCD from "../CardapioDigital/WhyCD.jsx";
import ContactCD from "../shared/ContactCD.jsx";
// shared page components
import PowerfulFeaturesCD from "../shared/PowerfulFeaturesCD.jsx";

class CardapioDigital extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactMessageFromPricingSelected: ''
    };
  }

  render() {
    const { contactMessageFromPricingSelected } = this.state
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 1 specific components */}
          <Heading />
          
          <FeaturesCard />
          <WhyCD />
          {/*<Proposal />*/}

          {/* Shared Components */}
          <PowerfulFeaturesCD />
          {/*<Dashboard />*/}
          {/*<Marketplace />*/}
          <PricingCD contactMessageStateChange={
            (value) => { this.setState({ contactMessageFromPricingSelected: value }) }
          } />
          {/*<Integration />*/}
          
          <FaqsCD />
          {/*<StartFree />*/}
          <ContactCD message={contactMessageFromPricingSelected} />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default CardapioDigital;

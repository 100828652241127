import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/sass/pedimosnamesa.scss";

const renderFeatures = () => {
  let features = [];
  const items = [
    {
      name: "Integração",
      icon: "plug",
      description:
        "Com um dashboard (painel de controle) completo, faça a integração do seu cardápio fisíco com o digital.",
    },
    {
      name: "Marketing",
      icon: "heart",
      description: "Faça o marketing dos seus produtos de forma digital.",
    },
    {
      name: "Suporte",
      icon: "headphones",
      description: "Solicite ajuda que teremos o prazer em ajudá-lo.",
    },
  ];

  items.forEach((f, i) => {
    features.push(
      <li className="list-item" key={i}>
        <div className="media align-items-center">
          <div className="icon-shape mr-3">
            <div className="shape shape-pipes" />
            <FontAwesomeIcon
              icon={["fas", f.icon]}
              className="icon fa-3x text-alternate"
            />
          </div>

          <div className="media-body">
            <h5 className="bold">{f.name}</h5>
            <p className="my-0">{f.description}</p>
          </div>
        </div>
      </li>
    );
  });

  return features;
};

const WhyUs = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="section-heading text-justify" id="box">
          <h2 className="heading-line">O que é o QR-Folder?</h2>
          <p className="text-muted lead">
            O QR-Folder é uma ferramenta que chega para modernizar o seu
            negócio! Com ele a interação entre colaborador e cliente será
            digital, trazendo uma maior comodidade, agilidade e segurança no
            momento do atendimento.<br/> A Pedimos entende que é um momento diferente
            pós-pandêmico onde a tecnologia está cada vez mais presente nos
            estabelecimentos, pensando nisso, com a leitura de um QR-Code por
            meio da camera de um celular é possível linkar qualquer conteúdo.
            Este poderá ser um panfleto digital de promoção, endereço de uma
            rede social, um arquivo com uma imagem, um pdf, enfim, as
            possibilidades são muitas. Além disso, o QR-Folder também contribui
            para a divulgação do seu negócio, podendo ser compartilhado nas suas
            redes sociais. Optando pelo nosso produto, você já vê o valor a ser
            investido e não tem surpresas no momento do pagamento. 
          </p>
        </div>

        <div className="row gap-y">
          <div className="col-md-6">
            <h4 className="bold text-alternate">
              Consulte o folder lendo um QR-Code.
            </h4>
            <p className="text-muted lead mb-5">
              Ofereça aos seus clientes a possibilidade de consultar o folder de
              forma digital, utilizando o celular.
            </p>

            <ul className="list-unstyled why-icon-list">{renderFeatures()}</ul>

            <hr className="my-5" />
            <div className="text-center text-md-left">
              <a href="#contactqr" className="btn btn-primary text-contrast">
                Quero saber mais
              </a>
            </div>
          </div>

          <div className="col-md-6">
            <div className="rotated-mockups device-twin">
              <div className="browser absolute shadow-lg">
                <img
                  src={require("assets/img/screens/wmockup/2.png")}
                  alt="..."
                />
              </div>

              <div className="front iphone light">
                <div className="screen">
                  <img
                    src={require("assets/img/screens/wmockup/2.png")}
                    alt="..."
                  />
                </div>

                <div className="notch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;

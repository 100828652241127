import React from "react";
import { Container, Row, Col } from "reactstrap";

class FeaturesCard extends React.Component {
  createCards = () => {
    let features = [];
    let cards = [
      {
        icon: "config",
        title: "Controle",
        description:
          "Painel de controle simples e objetivo para o gerenciamento do produtos."
      },
      {
        icon: "rocket",
        title: "Agilidade",
        description:
          "O acesso ao cardápio é rápido e fácil. Basta apontar a câmera do celular para o qr-code da mesa."
      },
      {
        icon: "less",
        title: "Sem instalação",
        description:
          "Não é necessário instalar nenhum aplicativo. Utiliza o navegador do celular."
      }
    ];

    cards.forEach((el, i) => {
      features.push(
        <Col md="4" key={i}>
          <div className="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
            <i className={`icon m-0 pe pe-7s-${el.icon} pe-3x`} />
          </div>
          <h4 className="semi-bold mt-4 text-capitalize">{el.title}</h4>

          <hr className="w-25 bw-2 border-alternate mt-3 mb-4" />
          <p className="regular text-muted">{el.description}</p>
        </Col>
      );
    });

    return features;
  };

  render() {
    return (
      <section className="section mt-6n" id="features">
        <Container className="pt-0">
          <div className="bg-contrast shadow rounded p-5">
            <Row className="gap-y">{this.createCards()}</Row>
          </div>
        </Container>
      </section>
    );
  }
}

export default FeaturesCard;

import React from "react";
import Swiper from "react-id-swiper";
import "swiper/dist/css/swiper.css";

const params = {
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnIteration: false
  },
  slidesPerView: 3,
  spaceBetween: 30
};

const linksImages = {
  "cd": "/cd",
  "qr": "/QRFolder",
  "mkt": "/",
  "pdm": "/pdm",
}

const listProducts = [
  'cd',
  'qr',
  'mkt',
  'pdm',
]

const Partners = () => {
  return (
    <section class="section">
      <div class="container py-5 border-bottom" style={{marginTop:-70}}>
        <Swiper {...params}>
          { listProducts.map(i => (
            <div class="swiper-slide">
              <a href={linksImages[i]}>
                <img
                  src={require(`assets/img/logos/${i}.svg`)}
                  class="img-responsive"
                  alt=""
                  style={{ maxHeight: "200px" }}
                />
              </a>
            </div>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;

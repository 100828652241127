import classNames from "classnames/bind";
import MoreInfoButton from "components/MoreInfoButton";
import React, { useState } from "react";
import {
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
/*import "odometer/themes/odometer-theme-minimal.css";*/
import "../../assets/sass/navbar.scss";

const PricingPlanCD = (props) => {
  const plan = props;
  var { contactMessageStateChange } = props;

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  return (
    <Col
      md="4"
      className={classNames(`max-princing-size pricing-plan plan-${plan.name} ${plan.class}`, {
        "best-value": plan.best,
      })}
    >
      <div className={classNames("p-5", { "": plan.desc })}>
        <div className="text-center">
          <h4
            className={`bold text-capitalize text-${
              plan.best ? "primary" : "alternate"
            }`}
          >
            {plan.name}
          </h4>
          <p>{plan.desc}</p>

          <div className="pricing-details">
            <span className="pricing-value">
              <span>
                {plan.price[props.basis].toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })}
              </span>
            </span>
          </div>
          <a href="#contactcd">
            <MoreInfoButton
              className={`btn btn-${plan.best ? "" : "outline-"}alternate mb-3`}
              text="Eu quero esse!"
              onClick={() => {
                contactMessageStateChange(plan.name);
              }}
            />
          </a>
        </div>

        <hr />

        <div className="d-none d-md-flex">
          <PlanFeatures plan={plan} />
        </div>

        <div className="d-md-none">
          <button
            type="button"
            className="more-link btn btn-link d-flex align-items-center mx-auto"
            onClick={toggleModal}
          >
            Veja detalhes do planos
          </button>

          <Modal isOpen={modal} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>Plano {plan.name}</ModalHeader>
            <ModalBody>
              <PlanFeatures plan={plan} />
            </ModalBody>
          </Modal>
        </div>
      </div>
    </Col>
  );
};

function PlanFeatures({ plan }) {
  return (
    <ListGroup flush className=" list-group-no-border">
      <ListGroupItem>{plan.qr}</ListGroupItem>
      <ListGroupItem>{plan.delivery}</ListGroupItem>     
      {<ListGroupItem>{plan.first_feature}</ListGroupItem>} 
      {<ListGroupItem>{plan.second_feature}</ListGroupItem>}
      <ListGroupItem>{plan.domain}</ListGroupItem>
      <ListGroupItem>{plan.third_feature}</ListGroupItem>
      <ListGroupItem>{plan.installation}</ListGroupItem>
      <ListGroupItem>{plan.support}</ListGroupItem>
      <ListGroupItem>{plan.monthly}</ListGroupItem>
    </ListGroup>
  );
}

export default PricingPlanCD;

import React from "react";
import { Container, Row, Col } from "reactstrap";
import PowerfulCard from "./PowerfulCard.jsx";

const createCards = use => {
  let features = [];
  let cards = [
    {
      class: "mt-6 mt-6 mx-lg-auto",
      icon: "magic-wand",
      title: "Agilidade & Transparência",
      animation: { delay: 0 },
      description: "O cliente tem acesso digital e rápido ao cardápio, visualizando as imagens dos produtos desejados."
    },

    {
      class: "mx-lg-auto",
      icon: "cloud-upload",
      title: "Atualização Fácil",
      animation: { delay: 800 },
      description: "Criamos um painel de controle fácil de ser gerenciado, em que permite realizar as alterações de forma rápida, porque sabemos da necessidade de realizar alterações nos produtos e preços com frequência."
    },

    {
      class: "mr-lg-auto",
      icon: "radio",
      title: "Divulgação",
      animation: { delay: 500 },
      description: "Disponibilize o seu cardápio de forma fácil nas redes sociais, como Facebook e Instagram."
    },

    {
      class: "mt-6n mr-lg-auto",
      icon: "mail-open-file",
      title: "Suporte Ilimitado",
      animation: { delay: 1200 },
      description: "Contato através de e-mail e WhatsApp."
    }
  ];

  cards.forEach((el, i) => {
    if (i % 2 === use) {
      features.push(<PowerfulCard {...el} key={i} />);
    }
  });

  return features;
};

const PowerfulFeatures = () => {
  return (
    <section className="section alter3-features">
      <div className="shapes-container">
        <div className="shape shape-ring shape-ring-1">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-ring shape-ring-2">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-circle animation--clockwise">
          <div />
        </div>

        <div className="shape background-shape-main" />
      </div>

      <Container>
        <Row>
          <Col lg="5" className="order-lg-last">
            <div className="section-heading">
              <p className="px-2 text-alternate text-uppercase bold">
                Usar o Pedimos Cardápio Digital
              </p>
              <h2 className="heading-line" style={{textAlign: 'left'}}>
                Motivos para escolher a Pedimos Cardápio Digital
              </h2>

              <p className="lead text-muted my-4 semi-bold">
                Estamos em um momento diferente, precisamos aliar-nos às tecnologias para faturar mais.
              </p>

              <p>
              Um cardápio digital permite que cada cliente utilize o seu celular para visualizar os produtos e preços, 
              ainda, utilizando o seu celular poderá fazer o pedido ainda chamar um garçom apenas utilizando o seu
              telefone. {" "}
              </p>
            
              <div className="text-center text-md-left">
              <a href="#contactcd" className="btn btn-primary text-contrast">
                Quero ter
              </a>
            </div>
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
            <Row>
              <Col lg="6" className="rotated-cards">
                {createCards(0)}
              </Col>
              <Col lg="6" className="rotated-cards">
                {createCards(1)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PowerfulFeatures;

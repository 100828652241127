import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CardapioDigital from "views/landing/CardapioDigital.jsx";
import Developer from "views/landing/Developer.jsx";
import SocialMedia from "views/landing/SocialMedia.jsx";
import RedirectToMenu from "views/landing/RedirectToMenu.jsx";
import Pedimos from "views/landing/Pedimos.jsx";
import QRFolder from "views/landing/QRFolder.jsx";
import FloatingButton from 'components/FloatingButton'
// global template script file
import "./laapp.js";
import "./assets/sass/global.scss";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Pedimos {...props} />} />

      <Route
        path="/cd"
        exact
        render={props => <CardapioDigital {...props} />}
      />

      <Route
        path="/socialmedia"
        exact
        render={props => <SocialMedia {...props} />}
      />
      
      <Route
        path="/cd/index.php"
        exact
        render={props => <RedirectToMenu {...props} />}
      />

      <Route
        path="/index"
        exact
        render={props => <Pedimos {...props} />}
      />
      <Route
        path="/pdm"
        exact
        component={props => <Developer {...props} />}
      />
      <Route
        path="/QRFolder"
        exact
        render={props => <QRFolder {...props} />}
      />

      <Redirect to="/" />
    </Switch>
    <FloatingButton
      icon={['fas', 'coffee']}
      to="https://api.whatsapp.com/send?phone=5551992844939"
      className="whatsapp"
    />
  </BrowserRouter>,
  document.getElementById("root")

);

import React from "react";
import "../../assets/sass/cd.scss";
import { Container, Col } from "reactstrap";

const ServicesPDM = () => {
  return (
    <section style={{marginBottom:"-200px"}} class="section section-heading text-justify">     
      <Container className="container-services">
      <h2 class="heading-line"><font size="6.5"> Principais produtos</font></h2>
        <div className="row-container">
          <a id="link-services" href="/cd">
            <Col className="bg-light border" id="coluna">            
              <img src={require("assets/img/cd-logo.png")} style={{ width: "100%"}} alt="..." />             
            </Col>
          </a>
          <a id="link-services" href="/QRFolder">
            <Col className="bg-light border" id="coluna1" />
          </a>         
          <a id="link-services" href="/pdm">
            <Col className="bg-light border" id="coluna3">   
            <img src={require("assets/img/PDM_logo.svg")} alt="..." />          
            </Col>
          </a>
        </div>
      </Container>
    </section>
  );
};

export default ServicesPDM;
